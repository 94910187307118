import React from "react"

import HeroformOverlayService from "../../../services/heroform-overlay-service"
import styles from "./heroform.module.scss"

import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"

export function getModalTitle(success) {
  if (success) {
    return (<Translate id={"form.thankYouHeader"} />)
  }

  const { action, block } = HeroformOverlayService._from || {}
}

export function getModalDescription(success, registrationTokenSentToCustomer) {

  if (!success) {
    return (<div>{htmlStr()}</div>)
  }
  return <Translate id={"form.thankYouMessageWithEmail"} />
}

function htmlStr() {
  return (
    <div className={styles.head}>
      <div className={styles.title}>Хотите бесплатный тест CRM 10 дней?</div>
      <div className={styles.descr}>
        Заполните эту форму и мы вышлем доступ к системе.
      </div>
    </div>
  )
}
