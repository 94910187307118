import React from "react"
import styles from "./callback-form.module.scss"
import Input from "../../controls/input"
import Checkbox from "../../controls/checkbox"
import callbackService from "../../../services/callback-service"
import callbackOverlayService from "../../../services/callback-overlay-service"
import LoaderComponent from "../shared/loader"
import SuccessComponent from "../shared/success"
import TextArea from "../../controls/textarea"
import Translate from "../../../i18n/Translate"
import { graphql, useStaticQuery } from "gatsby"
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { ReCaptcha } from "react-recaptcha-google"

function useCaptchaToken() {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            captchaToken
          }
        }
      }
    `,
  )
  return site.siteMetadata.captchaToken;
}

// todo refactor to functional component
class CallbackFormComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      inProgress: false,
      termsAccepted: true,
      name: "",
      phone: "",
      email: "no-reply@paraplancrm.ru",
      comment: "",
      validation: null,
      showCaptcha: false,
      captcha: null,
    }
  }

  componentDidMount() {
    if (this.captchaElement) {
      this.captchaElement.reset()
    }
  }

  onAgreementChange = (event) => {
    const termsAccepted = event.target.checked
    this.setState({ termsAccepted })
  }

  validatePhoneNumber = (number) => {
    const isValidPhoneNumber = isMobilePhone(number)
    return (isValidPhoneNumber)
  }

  sendForm = async () => {
    const { name, phone,  email, inProgress, captcha } = this.state
    if (inProgress) {
      return
    }

    const comment = this.state.comment || callbackOverlayService.defaultMessage

    if( !this.validatePhoneNumber(phone) ) {
      this.setState({
        validation: {
          fieldErrorCodeList: {
            phone: 'required'
          }
        }
      })
      return
    }

    this.setState({ inProgress: true })
    callbackService.send(name, phone, email, comment, captcha, this.props.lang)
      .then(validation => this.setState({ validation }))
      .then(() => this.setState({ inProgress: false }))
      .then(() => this.isSuccess ? this.props.onSuccess(this.state.validation["registrationTokenSentToCustomer"]) : null)
      .then(() => this.setState({
        showCaptcha: this.needToShowCaptcha,
        captcha: null,
      }))
  }

  handleFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  get isSuccess() {
    const { validation } = this.state
    return validation && validation.success
  }

  get needToShowCaptcha() {
    const { validation } = this.state
    return validation && validation.fieldErrorCodeList && validation.fieldErrorCodeList.captcha
  }

  // Генерирует уникальный elementId для ReCaptcha, чтобы могло рендериться несколько элементов
  generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`
  }

  onLoadRecaptcha() {
    if (this.captchaElement) {
      this.captchaElement.reset()
    }
  }

  handleChange = recaptchaToken => {
    this.setState({ captcha: recaptchaToken })
  }

  render() {
    const { termsAccepted, validation, inProgress, showCaptcha } = this.state
    const { onCloseRequested, showMessage } = this.props

    const policy = <div className={styles.policy}>
      <Checkbox checked={termsAccepted}
                onChange={this.onAgreementChange}>
        <span><Translate id={"form.disclaimer"}/></span>
      </Checkbox>
    </div>

    return <Translate>
      {
        (translate) => !validation || !validation.success
          ? (
            <>
              <div className={`${styles.form} ${showMessage ? styles.withMessage : ""}`}>
                <Input name={"name"}
                       validation={validation}
                       placeholder={translate("form.name")}
                       value={this.state.name}
                       onChange={this.handleFieldChange("name")}
                       inputClassName={"gtm_send-input"}
                       maxLength={50}/>
                <Input name={"phone"}
                       validation={validation}
                       placeholder={translate("form.phone")}
                       value={this.state.phone}
                       onChange={this.handleFieldChange("phone")}
                       inputClassName={"gtm_send-input"}
                       maxLength={255}/>
               <TextArea
                       name={"message"}
                       validation={validation}
                       placeholder={translate("form.yourQuestions")}
                       value={this.state.comment}
                       onChange={this.handleFieldChange("comment")}
                       textAreaClassName={"gtm_send-input"}
                       rows={4}
                       maxLength={500}/>
                       
                {policy}
                {
                    showCaptcha ? <ReCaptcha ref={(el) => this.captchaElement = el}
                                                          elementID={this.generateKey()}
                                                          hl={translate("form.captchaLanguage")}
                                                          size="normal"
                                                          theme="light"
                                                          onloadCallback={this.onLoadRecaptcha}
                                                          verifyCallback={this.handleChange}
                                                          sitekey={useCaptchaToken()}/>
                    : <div hidden={true}/>
                }
                <button disabled={!termsAccepted}
                        className={`${styles.send} gtm_send-btn`}
                        onClick={this.sendForm}>
                  {inProgress ? <LoaderComponent/> : translate("form.orderCall")}
                </button>
                <div className={styles.messengers}>
                  <p className={styles.social}>
                    Для быстрого получения ответа на Ваш запрос напишите в ЧАТ Службы заботы.
                  </p>
                <div className={styles.wrap}>
                  <a className={styles.messenger} href={"https://t.me/paraplancrm"} target={"_blank"}
                    rel={"noopener nofollow"}>
                    <img src={require("../../../images/support/telegram.svg")} alt="Telegram"/>
                  </a>
                  <a className={styles.messenger} href={"https://wa.me/79991709714"} target={"_blank"}
                    rel={"noopener nofollow"}>
                    <img src={require("../../../images/support/whatsapp.svg")} alt="WhatsApp"/>
                  </a>
                </div>
              </div>
          </div>
            </>)
          : (
            <>
              <SuccessComponent/>
              {
                onCloseRequested
                  ? <div className={styles.closeButton}>
                    <button className={styles.send}
                            onClick={onCloseRequested}>
                      {translate("form.close")}
                    </button>
                  </div>
                  : null
              }
            </>
          )
      }
    </Translate>
  }
}

export default CallbackFormComponent
