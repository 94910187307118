import React from "react"

import BecomepartnerOverlayService from "../../../services/becomepartner-overlay-service"
import styles from "./becomepartner.module.scss"
import pricingInfo from "../../pages/main/pricing/pricing-info"
import Translate from "../../../i18n/Translate"
import { LangContext } from "../../lang-context"

export function getModalTitle(success) {
  if (success) {
    return <Translate id={"form.thankYouHeaderCallback"} />
  }

  const { action, block } = BecomepartnerOverlayService._from || {}
  if (block === "pricing") {
    return getPricingHeaders(action)
  } else {
  }
}

export function getModalDescription(success, registrationTokenSentToCustomer) {

  if (!success) {
    return (<div>{htmlStr()}</div>)
  }
  return <Translate id={registrationTokenSentToCustomer ? "form.thankYouMessageBecomepartner" : "form.thankYouMessage"} />
}

function htmlStr() {
  return (
    <div className={styles.head}>
      <div className={styles.title}>Хотите стать партнером Параплан CRM?</div>
      <div className={styles.descr}>
        Заполните эту форму и наш менеджер свяжется с вами для обсуждения условий сотрудничества.
      </div>
    </div>
  )
}

function getPricingHeaders(action) {
  return <LangContext.Consumer>
    {
      (lang) => {
        const { name } = pricingInfo(lang).find(info => info.code === action)
        return action !== "custom"
          ? <Translate>{translate => translate("form.pricingPlan")(name)}</Translate>
          : name
      }
    }
  </LangContext.Consumer>
}
