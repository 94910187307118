import React, { useContext, useState } from "react"
import { getModalDescription, getModalTitle } from "./utils"
import BaseOverlayComponent from "../base-overlay"
import CallbackOverlayService from "../../../services/callback-overlay-service"
import CallbackFormComponent from "../../forms/callback"
import callbackService from "../../../services/callback-service"
import { LangContext } from "../../lang-context"

let overlayComponent

const CallbackOverlayComponent = ({ show }) => {
  const lang = useContext(LangContext);
  const [success, setSuccess] = useState(false)
  const [registrationTokenSentToCustomer, setRegistrationTokenSentToCustomer] = useState(false)
  return (
    <BaseOverlayComponent show={show}
                          title={getModalTitle(success)}
                          description={getModalDescription(success, registrationTokenSentToCustomer)}
                          onClose={() => setSuccess(false)}
                          controller={CallbackOverlayService}
                          ref={overlay => overlayComponent = overlay}>
      <CallbackFormComponent
        lang={lang}
        onSuccess={(registrationTokenSentToCustomer) => {
          setSuccess(true)
          setRegistrationTokenSentToCustomer(registrationTokenSentToCustomer)
          callbackService.fbPixelLeadEvent()
        }}
        onCloseRequested={() => overlayComponent.close()}/>
    </BaseOverlayComponent>
  )
}

export default CallbackOverlayComponent
