import React, { useContext, useState } from "react"
import { getModalDescription, getModalTitle } from "./utils"
import BaseOverlayComponent from "../base-overlay"
import PresentationOverlayService from "../../../services/presentation-overlay-service"
import PresentationFormComponent from "../../forms/presentation"
import presentationService from "../../../services/presentation-service"
import { LangContext } from "../../lang-context"

let overlayComponent

const PresentationOverlayComponent = ({ show }) => {
  const lang = useContext(LangContext);
  const [success, setSuccess] = useState(false)
  const [registrationTokenSentToCustomer, setRegistrationTokenSentToCustomer] = useState(false)
  return (
    <BaseOverlayComponent show={show}
                          title={getModalTitle(success)}
                          description={getModalDescription(success, registrationTokenSentToCustomer)}
                          onClose={() => setSuccess(false)}
                          controller={PresentationOverlayService}
                          ref={overlay => overlayComponent = overlay}>
      <PresentationFormComponent
        lang={lang}
        onSuccess={(registrationTokenSentToCustomer) => {
          setSuccess(true)
          setRegistrationTokenSentToCustomer(registrationTokenSentToCustomer)
          presentationService.fbPixelLeadEvent()
        }}
        onCloseRequested={() => overlayComponent.close()}/>
    </BaseOverlayComponent>
  )
}

export default PresentationOverlayComponent
