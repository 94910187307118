import React, { useContext, useState } from "react"
import { getModalDescription, getModalTitle } from "./utils"
import BaseOverlayComponent from "../base-overlay"
import BecomepartnerOverlayService from "../../../services/becomepartner-overlay-service"
import BecomepartnerFormComponent from "../../forms/becomepartner"
import becomepartnerService from "../../../services/becomepartner-service"
import { LangContext } from "../../lang-context"

let overlayComponent

const BecomepartnerOverlayComponent = ({ show }) => {
  const lang = useContext(LangContext);
  const [success, setSuccess] = useState(false)
  const [registrationTokenSentToCustomer, setRegistrationTokenSentToCustomer] = useState(false)
  return (
    <BaseOverlayComponent show={show}
                          title={getModalTitle(success)}
                          description={getModalDescription(success, registrationTokenSentToCustomer)}
                          onClose={() => setSuccess(false)}
                          controller={BecomepartnerOverlayService}
                          ref={overlay => overlayComponent = overlay}>
      <BecomepartnerFormComponent
        lang={lang}
        onSuccess={(registrationTokenSentToCustomer) => {
          setSuccess(true)
          setRegistrationTokenSentToCustomer(registrationTokenSentToCustomer)
          becomepartnerService.fbPixelLeadEvent()
        }}
        onCloseRequested={() => overlayComponent.close()}/>
    </BaseOverlayComponent>
  )
}

export default BecomepartnerOverlayComponent
