import axios from "axios"
import CallbackOverlayService from "./callback-overlay-service"
import PageService from "./page-service"
import { buildEventSource } from "../utils/overlay"
import { getUtm, readCookie } from "../utils/analytics"

class CallbackService {

  send(name, phone, email, comment, captcha, lang) {
    const data = this.getFeedbackRequest(name, phone, email, comment)
    return axios.put(this.getFeedbackUrl(lang), data, {
      headers: {
        "Content-Type": "application/json",
        "captcha-response": captcha,
      },
    })
      .then(response => response.data)
      .catch(error => this.handleError(error))
  }

  getFeedbackUrl(lang) {
    switch (lang) {
      case 'en':
        return "/en/api/public/feedback"
      case 'de':
        return "/de/api/public/feedback"
      default:
        return "/api/public/feedback"
    }
  }

  get yclid() {
    return readCookie("yclid")
  }

  get ymuid() {
    return readCookie('_ym_uid');
  }

  get gcid() {
    try {
      return window.ga.getAll()[0].get("clientId")
    } catch (e) {
      return "possibly-adblock"
    }
  }

  get promoCode() {
    return readCookie("promoCode")
  }

  fbPixelLeadEvent() {
    if (window.fbq) {
      window.fbq("track", "Lead")
    }
  }

  getFeedbackRequest(name, phone, email, comment) {
    const from = CallbackOverlayService._from
      ? CallbackOverlayService._from
      : buildEventSource(PageService._page, "form", "send")

    const fromWithDetails = {
      ...from,
      cid: this.gcid,
      yclid: this.yclid,
      _ym_uid: this.ymuid,
      promoCode: this.promoCode,
      comment: `ЗАКАЗ ОБРАТНОГО ЗВОНКА \n\n ${comment}`,
    }

    const message = this.formatMessage(fromWithDetails).substring(0, 1000)
    const utm = getUtm();

    return {
      name: this.trimValue(name),
      phone: this.trimValue(phone),
      email: this.trimValue(email),
      utm,
      message,
    }
  }

  formatMessage(data) {
    return `
      cid: ${data.cid}; 
      yclid: ${data.yclid};
      _ym_uid: ${data._ym_uid};
      промокод: ${data.promoCode};
      триггер: ${data.page}-${data.block}-${data.action};
      комментарий: ${data.comment || "не указан"}`
  }

  trimValue(value) {
    const trimmed = value.trim()
    return trimmed.length ? trimmed : null
  }

  handleError(error) {
    const response = error.response.data
    if (response.globalErrorCode === "noContactInfo") {
      response.globalErrorCode = null
      response.fieldErrorCodeList.phone = "required"
    }
    return response
  }

}

export default new CallbackService()
