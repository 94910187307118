import React, { useContext, useState } from "react"
import { getModalDescription, getModalTitle } from "./utils"
import BaseOverlayComponent from "../base-overlay"
import HeroformOverlayService from "../../../services/heroform-overlay-service"
import HeroformFormComponent from "../../forms/hero-form"
import heroformService from "../../../services/heroform-service"
import { LangContext } from "../../lang-context"
import SuccessComponent from "../../forms/shared/success"

let overlayComponent

const HeroformOverlayComponent = ({ show }) => {
  const lang = useContext(LangContext);
  const [success, setSuccess] = useState(false)
  const [registrationTokenSentToCustomer, setRegistrationTokenSentToCustomer] = useState(false)
  return (
    <BaseOverlayComponent show={show}
                          title={getModalTitle(true)}
                          description={getModalDescription(true, registrationTokenSentToCustomer)}
                          onClose={() => setSuccess(false)}
                          controller={HeroformOverlayService}
                          ref={overlay => overlayComponent = overlay}>
      <SuccessComponent />
      {/*}  <HeroformFormComponent
        lang={lang}
        onSuccess={(registrationTokenSentToCustomer) => {
          setSuccess(true)
          setRegistrationTokenSentToCustomer(registrationTokenSentToCustomer)
          heroformService.fbPixelLeadEvent()
        }}
        onCloseRequested={() => overlayComponent.close()}/>*/}
    </BaseOverlayComponent>
  )
}

export default HeroformOverlayComponent
