/*import React from "react"
import styles from "./custom-input.module.scss"

const Input = ({ placeholder, type, required }) => (
  <label className={styles.customInput}>
    <input className={styles.input} type={type} placeholder={placeholder} required={required} />
    <span className={styles.placeholder}>{placeholder}</span>
    <span className={styles.line}></span>
  </label>
)

export default Input*/
import React from "react"

import styles from "./custom-input.module.scss"
import Translate from "../../../i18n/Translate"

function getErrorCode(validation, name) {
  return validation && !validation.success && validation.fieldErrorCodeList && validation.fieldErrorCodeList[name]
}

function hasError(validation, name) {
  return !!getErrorCode(validation, name)
}

const Input = ({ validation, name, inputClassName, ...props }) => (
  <div className={`${styles.wrapper} ${hasError(validation, name) ? styles.hasError : ""}`}>
    <input className={`${styles.input} ${inputClassName}`} {...props} />
    <div className={styles.error}>
      <Translate>
        {translate => {
          const errorCode = getErrorCode(validation, name)
          return errorCode ? translate(`field.${errorCode}`) : null
        }}
      </Translate>
    </div>
  </div>
)

export default Input
